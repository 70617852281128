import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import UserService from 'src/services/UserService'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'

import { useGlobalContex } from 'src/context/GlobalContext'
import { useNavigate } from 'react-router-dom'

const LoginCallback = ({ handleAuthState }) => {
  const { isLoading, error, user, isAuthenticated } = useAuth0()
  const [cookie, setCookie] = useCookies()
  const navigate = useNavigate()
  const globalCtx = useGlobalContex()
  const [_user, setUser] = globalCtx.user
  const [_isAuthenticated, setIsAuthentiCated] = globalCtx.auth

  const getUserDetails = async () => {
    const idToken = localStorage.getItem(
      '@@auth0spajs@@::lF79Pk6CNdRbGghDsQhECaEPqPdJqNY2::@@user@@',
    )
      ? JSON.parse(
          localStorage.getItem('@@auth0spajs@@::lF79Pk6CNdRbGghDsQhECaEPqPdJqNY2::@@user@@'),
        ).id_token
      : null
    const userService = new UserService()
    const response = await userService.oktaLogin({ email: user.email, type: 'backend', idToken })
    console.log(response)
    if (response.data) {
      handleAuthState(true)
      setIsAuthentiCated(true)
      setUser(response.data)
      setCookie('token', response.token, {
        path: '/',
        maxAge: 86400 * 7, // Expires after 7 days
        // sameSite: true,
        sameSite: 'lax',
        domain: process.env.REACT_APP_PUBLIC_DOMAIN,
      })
      toast.success(response?.message)
      navigate('/dashboard')
    } else {
      console.log(response)
      toast.error(response?.error)
      navigate('/login')
    }
  }

  useEffect(() => {
    if (!isLoading) {
      getUserDetails()
    }
  }, [isAuthenticated])

  return null
}

export default LoginCallback
