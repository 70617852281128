import moment from 'moment'
export default {
  async sendApiRequest(url, method, setauth, body) {
    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }
    if (method === 'DELETE') {
      // delete requestOptions.body;
    }
    if (method === 'GET') {
      delete requestOptions.body
    }

    if (setauth === true) {
      let token = localStorage.getItem('token') ? localStorage.getItem('token') : 'no-token'
      requestOptions.headers['Authorization'] = 'Bearer ' + token
    }
    if (setauth === true) {
      let token = window.user ? window.user.token : 'no-token'
      requestOptions.headers['Authorization'] = 'Bearer ' + token
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_BASEURL + url, requestOptions)
      let body = await response.text()
      if (response.status != 200) {
        throw body
      }
      const data = body.includes('{') ? JSON.parse(body) : body
      return data
    } catch (e) {
      throw e
    }
  },
  timeSince(dateString) {
    return moment(dateString).fromNow()
  },
  getDate(date) {
    var d = new Date(date);
    var year = d.getFullYear();
    var month = String(d.getMonth() + 1).padStart(2, '0');
    var day = String(d.getDate()).padStart(2, '0');
    var hours = String(d.getHours()).padStart(2, '0');
    var minutes = String(d.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} at ${hours}:${minutes}`;
  },

  checkFileExtension(string,allowedExtensions) {
    let extension = string.toLowerCase().slice(((string.lastIndexOf('.') - 1) >>> 0) + 2);
    let allowed= true;
    // const allowedExtensions = ['mp4', 'mov', 'jpeg', 'jpg', 'png'];
    if (!allowedExtensions.includes(extension)) {
      allowed= false
      throw new Error(`File type not allowed. Please upload ${allowedExtensions} file formats files.`);
    }
    return allowed;
  },
  checkFileExtensionTestimonial(string) {
    let extention = string.toLowerCase().slice(((string.lastIndexOf('.') - 1) >>> 0) + 2)
    return extention
  },


  getUserLocal() {
    return JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : null
  },

  getThumbnailUrl(embedUrl) {
    const videoId = embedUrl.match(/\/embed\/([^?]+)/)[1]

  
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`

    return thumbnailUrl
  },
  generateUniqueString() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  },

  async dataUrlToFile(url, fileName) {
    const [mediaType, data] = url.split(',')

    const mime = mediaType.match(/:(.*?);/)?.[0]
    const response = await fetch(url)
    const buffer = await response.arrayBuffer()

    return new File([buffer], fileName, { type: mime })
  },
}
